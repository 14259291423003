/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isNotNil } from "~/tools";
import {
  Option,
  SelectedPlanEntity,
  StepState,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { TimeUnits } from "../../types";

export const buildOptions = (arr?: string[]): Option[] => {
  return (
    arr?.map((i: string) => ({
      label: i ?? "",
      value: i ?? "",
    })) ?? []
  );
};

export function convertStringToTimeUnit(value: string): TimeUnits | undefined {
  return TimeUnits[value as keyof typeof TimeUnits];
}

export const updateEntities = (
  stepState: StepState,
  updateObject: Partial<SelectedPlanEntity>
) => {
  const isBatchAction =
    !isBatch(stepState.selectedEntity) &&
    isNotNil(stepState.selectedEntity?.batchId);

  const mapEntity = (entity: SelectedPlanEntity) => {
    if (isBatchAction && entity.listId === stepState.selectedEntity?.batchId) {
      return {
        ...entity,
        batchActions:
          entity.batchActions?.map((action) => {
            if (action.listId === stepState.selectedEntity?.listId) {
              return {
                ...action,
                ...updateObject,
                batchId: stepState.selectedEntity?.batchId,
              };
            }
            return action;
          }) ?? [],
      };
    }
    if (entity.listId === stepState.selectedEntity?.listId) {
      return {
        ...entity,
        ...updateObject,
      };
    }
    return entity;
  };
  return stepState.entities?.map(mapEntity).filter(isNotNil) ?? [];
};

export const isBatch = (entity?: SelectedPlanEntity) =>
  isNotNil(entity?.batchName);
