/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Input, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { getFirstItem } from "~/tools";
import { PlanActionNameSchema } from "~/views/plans/schemas";
import { PlanField } from "../../../constants";
import { usePlanWizard } from "../../../PlanProvider";
import { PlanEntity, SelectedPlanEntity } from "../../../types";
import { useStepState } from "../../StepProvider";
import { updateEntities } from "../utils";

export function PlanActionNameInput({
  item,
  handlePlanErrors,
}: {
  item: PlanEntity;
  handlePlanErrors: () => void;
}) {
  const {
    formState: { errors },
    setError,
    clearErrors,
    trigger,
  } = useFormContext();

  const { setPlanData } = usePlanWizard();

  const { stepState, updateStepState } = useStepState();

  const updateSelectedAction = (updateObject: Partial<SelectedPlanEntity>) => {
    const updatedEntities = updateEntities(stepState, updateObject);
    updateStepState({
      entities: updatedEntities,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: updatedEntities,
      },
    }));
  };

  const handleActionError = (name: string, listId: string) => {
    const isValid = PlanActionNameSchema.safeParse(name);
    if (!isValid.success) {
      setError(listId, {
        message: getFirstItem(isValid.error.issues).message,
      });
    } else {
      clearErrors(listId);
    }
  };

  return (
    <>
      <FormGroup
        error={errors[`${item.listId}`]}
        customCss={{
          marginBottom: theme.spacing.spacing03,
          marginTop: theme.spacing.spacing02,
        }}
      >
        <Input
          id={item.listId}
          defaultValue={item.name}
          onChange={(change) => {
            updateSelectedAction({ name: change.target.value.trim() });
            handleActionError(change.target.value, `${item.listId}`);
            handlePlanErrors();
          }}
          onFocus={() => {
            trigger();
          }}
          small
        />
      </FormGroup>
    </>
  );
}
