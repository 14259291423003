/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { KeyboardEvent, useState } from "react";
import styled from "styled-components";
import { InputSearch, theme } from "@nordcloud/gnui";
import { useQueryState, useSleep } from "~/hooks";
import { isNotNil } from "~/tools";
import { ResourceQuery } from "~/views/resources/types";
import { maxSearchLength, minSearchLength } from "../const";

type Props = {
  disabled?: boolean;
  delayedSearch?: boolean;
  placeholder?: string;
  minSearchLength?: number;
  searchDelay?: number;
  onSubmit: (query: string) => void;
};

export function ResourceSearchInput({
  onSubmit,
  placeholder = "Search",
  searchDelay = 600,
}: Props) {
  const {
    queryState: { searchPhrase },
    updateQueryState,
  } = useQueryState<ResourceQuery>();

  const [searchInput, setSearchInput] = useState<string>(searchPhrase ?? "");
  const { sleep, cancelSleep } = useSleep();

  const onSearch = (phrase?: string) => {
    const toSearch = isNotNil(phrase) ? phrase : searchInput;
    if (toSearch && shouldSearch(toSearch)) {
      onSubmit(toSearch);
    }
  };

  const handleKeyPress = (
    event: KeyboardEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      updateQueryState({ searchPhrase: event.target.value });
      onSearch(event.target.value);
    }
  };

  const shouldSearch = (query: string): boolean => {
    return query.length >= minSearchLength;
  };

  const handleDelayedSearch = async (phrase: string) => {
    cancelSleep();
    await sleep(searchDelay);
    onSearch(phrase);
  };

  return (
    <Div>
      <InputSearch
        onKeyDown={handleKeyPress}
        value={searchInput ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value;
          setSearchInput(val);
          updateQueryState({ searchPhrase: val });
          handleDelayedSearch(val);
        }}
        placeholder={placeholder}
        onSearch={onSearch}
        name="search"
        minLength={minSearchLength}
        maxLength={maxSearchLength}
      />
    </Div>
  );
}

const Div = styled.div`
  width: 100%;
  input:invalid {
    color: ${theme.color.background.error};
  }
`;
