/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { PlanActionType } from "~/generated/graphql";
import { MultiSelectSubmitContainer } from "~/components";
import { inflect, isEmpty } from "~/tools";
import { SelectedPlanEntity } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { State } from "../../types";

type Props = {
  state: State;
  clear: () => void;
  onSubmit: (actions: SelectedPlanEntity[]) => void;
};

export function AddActionBatchSidebarFooter({ state, clear, onSubmit }: Props) {
  const isSelectedActionsEmpty = isEmpty(state.selectedActions);

  const { system, custom } = state.selectedActions.reduce(
    (acc, { actionType }) => ({
      ...acc,
      system: acc.system + (actionType === PlanActionType.System ? 1 : 0),
      custom: acc.custom + (actionType === PlanActionType.Custom ? 1 : 0),
    }),
    { system: 0, custom: 0 }
  );

  return (
    <MultiSelectSubmitContainer
      css={{ flexDirection: "column", alignItems: "flex-start" }}
    >
      <Text tag="div" size="sm" color={theme.color.text.text02}>
        <When condition={isSelectedActionsEmpty}>Select Action</When>
        <When condition={!isSelectedActionsEmpty}>
          {system} System {inflect("Action")(system)}, {custom} Custom{" "}
          {inflect("Action")(custom)} Selected
        </When>
      </Text>
      <FlexContainer justifyContent="flex-start" gap={theme.spacing.spacing04}>
        <Button
          onClick={() => onSubmit(state.selectedActions)}
          disabled={isSelectedActionsEmpty}
        >
          Apply
        </Button>
        <Button severity="low" onClick={clear}>
          Clear
        </Button>
      </FlexContainer>
    </MultiSelectSubmitContainer>
  );
}
