/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { When } from "react-if";
import { v4 } from "uuid";
import { Button, theme } from "@nordcloud/gnui";
import {
  defaultBatchSettingsValues,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { SelectedPlanEntity } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { defaultState } from "../constant";
import { ExecutionMethod, State } from "../types";
import { AddActionsSidebar } from "./AddActionsSidebar";
import { ActionBatchModal } from "./components";
import { createNewAction } from "./helpers";

type Props = {
  buttonSeverity?: "low";
  executionMethod: ExecutionMethod;
};

export function AddPlanEntity({ buttonSeverity, executionMethod }: Props) {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({
      ...data,
      ...partialData,
    }),
    defaultState
  );

  const { stepState, updateStepState } = useStepState();
  const { setPlanData, setSelectedEntity } = usePlanWizard();

  const applyEntities = (actions: SelectedPlanEntity[], batchName?: string) => {
    const actionItems = actions.map((actionItem) =>
      createNewAction(actionItem)
    );

    const newBatch = (newActions: SelectedPlanEntity[]) => {
      const batchId = v4();
      return [
        {
          ...defaultBatchSettingsValues,
          batchName: batchName,
          id: batchId,
          batchId: batchId,
          listId: batchId,
          batchActions: [...newActions],
        },
      ];
    };

    const entities =
      executionMethod === ExecutionMethod.batch
        ? newBatch(actionItems)
        : actionItems;

    updateStepState({
      selectedEntity: entities[entities.length - 1],
      entities: [...(stepState.entities ?? []), ...entities],
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        ...prevPlanData[PlanField.PLAN_SETTINGS],
        planEntities: [
          ...(prevPlanData[PlanField.PLAN_SETTINGS]?.planEntities ?? []),
          ...entities,
        ],
      },
    }));

    setSelectedEntity?.(entities[entities.length - 1].listId);
    updateState({ selectedActions: [], isSidebarOpen: false });
  };

  const onApplyBatch = () => {
    if (executionMethod === ExecutionMethod.batch) {
      updateState({ isModalOpen: true });
    }
  };

  return (
    <>
      <Button
        onClick={() => updateState({ isSidebarOpen: true })}
        mt={theme.spacing.spacing01}
        severity={buttonSeverity}
      >
        <When condition={executionMethod === ExecutionMethod.single}>
          Add Actions
        </When>
        <When condition={executionMethod === ExecutionMethod.batch}>
          Add Action Batch
        </When>
      </Button>
      <When condition={state.isSidebarOpen}>
        <AddActionsSidebar
          executionMethod={executionMethod}
          onApply={
            executionMethod === ExecutionMethod.batch
              ? onApplyBatch
              : applyEntities
          }
          {...{ state, updateState }}
        />
      </When>
      <When condition={executionMethod === ExecutionMethod.batch}>
        <ActionBatchModal {...{ state, updateState, applyEntities }} />
      </When>
    </>
  );
}
