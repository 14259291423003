/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";

export const useSleep = () => {
  const [reference, setReference] = useState<NodeJS.Timeout | undefined>();

  const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => {
      setReference(setTimeout(resolve, ms));
    });
  };

  const cancelSleep = () => {
    clearTimeout(reference);
  };

  return {
    sleep,
    cancelSleep,
  };
};
