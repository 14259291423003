/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useState } from "react";
import { Else, If, Then, When } from "react-if";
import { FlexContainer, Tab, Tabs as TabContainer } from "@nordcloud/gnui";
import { ActionScope } from "~/generated/graphql";
import { isNotEmpty } from "~/tools";
import { RedIndicator } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/RedIndicatior";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";
import { PlanErrors, PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { useStepState } from "../StepProvider";
import { ActionResourceGroups } from "./ActionResourceGroups";
import { ActionSettingsForm } from "./ActionSettingsForm";
import { BatchSettingsForm } from "./ActionSettingsForm/BatchSettingsForm";
import { AdvancedSettingsForm } from "./AdvancedSettingsForm";
import { NotificationForm } from "./NotificationForm";

type Props = {
  nextStep?: () => void;
};

export function ActionView({ nextStep }: Props) {
  const [tab, setTab] = useState(0);
  const {
    stepState: { selectedEntity },
    updateStepState,
  } = useStepState();

  const { planData } = usePlanWizard();

  const handleTab = useCallback(
    (key: number) => {
      setTab(key);
      updateStepState({
        selectedEntity: { ...selectedEntity, selectedTab: key },
      });
    },
    [selectedEntity, updateStepState]
  );
  const isError = (planData?.[PlanField.ERRORS] ?? []).includes(
    PlanErrors.PLAN_ACTION_SETTINGS
  );
  const isBatch = isNotEmpty(selectedEntity?.batchActions ?? []);

  return (
    <>
      <When condition={selectedEntity?.scope === ActionScope.Resource}>
        <ActionResourceGroups />
      </When>
      <FlexContainer css={{ flex: 1, minWidth: "10rem" }} alignItems="stretch">
        <If condition={!isBatch}>
          <Then>
            <TabContainer step={tab} handleTab={handleTab}>
              <Tab
                label={
                  <RedIndicator isVisible={isError}>
                    <TabIcon
                      caption="General Action Settings"
                      icon="settings"
                    />
                  </RedIndicator>
                }
                width="auto"
                css={{ minHeight: "13rem" }}
              >
                <ActionSettingsForm {...{ nextStep }} setTab={handleTab} />
              </Tab>
              <Tab
                label={
                  <TabIcon
                    caption="Advanced Action Settings"
                    icon="doubleGear"
                  />
                }
                width="auto"
                css={{ minHeight: "13rem" }}
              >
                <AdvancedSettingsForm {...{ nextStep }} setTab={handleTab} />
              </Tab>
              <Tab
                label={<TabIcon caption="Notification Settings" icon="bell" />}
                width="auto"
                css={{ minHeight: "13rem" }}
              >
                <NotificationForm {...{ nextStep }} />
              </Tab>
            </TabContainer>
          </Then>
          <Else>
            <TabContainer step={tab} handleTab={handleTab}>
              <Tab
                label={
                  <RedIndicator isVisible={isError}>
                    <TabIcon caption="Action Batch Settings" icon="settings" />
                  </RedIndicator>
                }
                width="auto"
                css={{ minHeight: "13rem" }}
              >
                <BatchSettingsForm {...{ nextStep }} />
              </Tab>
            </TabContainer>
          </Else>
        </If>
      </FlexContainer>
    </>
  );
}
