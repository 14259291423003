/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  Modal,
  StickyBar,
  theme,
} from "@nordcloud/gnui";
import {
  ExecutionPolicy,
  PlanActionNotificationTriggerType,
  PlanActionType,
} from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty } from "~/tools";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanField,
  maxInAdvance,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { TimeUnits } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { convertToMinutes, isAdvanceTrigger } from "~/views/plans/utils";
import { usePlan } from "../../PlanProvider";
import {
  mapPlanActionBatchToPlanEntity,
  mapPlanActionToPlanEntity,
  sortPlanEntitiesByOrder,
} from "./utils";

type Props = {
  openEditMode: () => void;
  closeEditMode: () => void;
  isEditMode: boolean;
};

export function DetailsTabBar({
  openEditMode,
  closeEditMode,
  isEditMode,
}: Props) {
  const { plan, setPlanAction, planAction } = usePlan();

  const { setPlanData, planData, setSelectedEntity, selectedEntity } =
    usePlanWizard();

  const editedPlanEntities = planData?.[PlanField.PLAN_SETTINGS]?.planEntities;
  const { isOpen, open, close } = useDisclosure();

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });
  const deleteActions = plan?.planActions
    ?.filter(
      (action) => !editedPlanEntities?.map((i) => i?.id).includes(action.id)
    )
    .map(({ id }) => id);

  const newActions = editedPlanEntities?.filter(
    (entityItem) =>
      !plan?.planActions?.map((i) => i?.id).includes(entityItem.id ?? "")
  );

  const updatedActions =
    editedPlanEntities?.map((action, index) => {
      const resourceGroupIds = action?.resourceGroupIds;

      const addPlanAction = newActions?.find((item) => item.id === action?.id);
      const editableAction = plan?.planActions?.find(
        (item) => item.id === action?.id
      );

      const addResourceGroup = resourceGroupIds?.filter(
        (groupId: string) =>
          !editableAction?.resourceGroups
            ?.map((groupItem) => groupItem.id)
            .includes(groupId)
      );

      const deleteResourceGroup = editableAction?.resourceGroups
        ?.map((groupItem) => groupItem.id)
        ?.filter((groupId: string) => !resourceGroupIds?.includes(groupId));

      const inputParams = action?.inputParameters?.map(({ key, value }) => ({
        key,
        value,
      }));

      const editableNotificationGroupIds =
        editableAction?.notificationGroups?.map((groupItem) => groupItem.id);

      const newNotificationGroupIds = action?.notificationGroups?.map(
        (groupItem) => groupItem.id
      );

      const deleteNotificationGroupsFromList =
        editableNotificationGroupIds?.filter(
          (groupId: string) => !newNotificationGroupIds?.includes(groupId)
        ) ?? [];

      const deleteNotificationGroupsFromNestedGroup: string[] =
        action?.notificationGroups
          ?.reduce<string[]>((acc, item) => {
            const matchingIds = editableAction?.notificationGroups?.reduce<
              string[]
            >((accInner, notification) => {
              if (
                item.id === notification.id &&
                !item.notificationGroupIds?.includes(
                  notification.notificationGroup.id
                ) &&
                JSON.stringify(item) !== JSON.stringify(notification)
              ) {
                return [...accInner, notification.id];
              }
              return accInner;
            }, []);
            return [...acc, ...(matchingIds || [])];
          }, [])
          ?.filter((item) => item !== undefined) ?? [].flat();

      const deleteNotificationGroups = [
        ...deleteNotificationGroupsFromList,
        ...deleteNotificationGroupsFromNestedGroup,
      ];

      const notificationGroups = action?.notificationGroups
        ?.map((group) => {
          const inAdvance = isAdvanceTrigger(group.triggerEvent)
            ? convertToMinutes(
                `${group.inAdvance ?? ""}`,
                group.inAdvanceUnit ?? TimeUnits.minutes
              )
            : 0;
          return (
            group.notificationGroupIds
              ?.filter((item) => item !== undefined)
              ?.map((groupId) => ({
                id: group.id ? group.id : undefined,
                notificationGroupId: groupId,
                scope: group.scope,
                triggerEvent:
                  group.triggerEvent ??
                  PlanActionNotificationTriggerType.ResourcesError,
                // TODO move maxSignedInt to schema validation
                inAdvance: inAdvance > maxInAdvance ? maxInAdvance : inAdvance,
              })) ?? []
          );
        })
        .flat();

      return {
        action: addPlanAction && {
          actionId: addPlanAction?.id ?? "",
          actionType: addPlanAction?.actionType ?? PlanActionType.System,
        },
        executionPolicy: action?.executionPolicy,
        id: addPlanAction ? undefined : action?.id,
        name: action.name,
        inputParameters: inputParams,
        notificationGroups: notificationGroups,
        notificationGroupsToDelete: deleteNotificationGroups ?? [],
        order: index + 1,
        resourceGroupIds: addResourceGroup ?? undefined,
        resourceGroupIdsToDelete: deleteResourceGroup ?? undefined,
        runInSequence: action.runInSequence,
        skipWindow: action.skipWindow,
        windowDuration: convertToMinutes(
          action.windowDuration?.toString() ?? "",
          action?.unit ?? TimeUnits.minutes
        ),
      };
    }) ?? [];

  type UpdatedActionsType = typeof updatedActions;

  const changeFirstActionPolicyFromSuccessOrApproval = (
    actions: UpdatedActionsType
  ) => {
    if (actions && actions.length > 0) {
      const firstAction = actions[0];
      if (firstAction?.executionPolicy === ExecutionPolicy.SuccessOrApproval) {
        const modifiedFirstAction = {
          ...firstAction,
          executionPolicy: ExecutionPolicy.Anyway,
        };
        return [modifiedFirstAction, ...actions.slice(1)];
      }
    }
    return actions;
  };

  const handlePlanErrors = () => {
    showError(
      <div>
        {"Please verify plan settings:"}
        {(planData?.[PlanField.ERRORS] ?? []).map((error) => {
          return <div key={error}> {`- ${error}`}</div>;
        })}
      </div>
    );
  };

  const editablePlanActions =
    plan?.planActions?.map(mapPlanActionToPlanEntity) ?? [];

  const editablePlanActionBatches =
    plan?.planActionBatches?.map(mapPlanActionBatchToPlanEntity) ?? [];

  const editablePlanEntities = sortPlanEntitiesByOrder([
    ...editablePlanActions,
    ...editablePlanActionBatches,
  ]);

  const setSelectedActionOnClose = () => {
    const editableEntitiesFlat = editablePlanEntities.flatMap((entity) => {
      if (entity.batchActions) {
        return [entity, ...entity.batchActions];
      }
      return entity;
    });

    if (
      !editableEntitiesFlat?.map((i) => i?.id).includes(selectedEntity ?? "")
    ) {
      setSelectedEntity?.(
        editablePlanEntities[editablePlanEntities.length - 1]?.listId
      );
    }
  };

  const handleSavePlan = () => {
    if (isNotEmpty(planData?.[PlanField.ERRORS] ?? [])) {
      handlePlanErrors();
      return;
    }
    const modifiedFirstAction =
      changeFirstActionPolicyFromSuccessOrApproval(updatedActions);

    updatePlan({
      id: plan?.id ?? "",
      planActions: modifiedFirstAction,
      planActionsToDelete: deleteActions,
    });

    setSelectedActionOnClose();
    closeEditMode();
  };

  const handleDiscardChanges = () => {
    setSelectedActionOnClose();
    closeEditMode();
    close();
  };

  const handleSaveChanges = () => {
    handleSavePlan();
    closeEditMode();
    close();
  };

  const handleEditMode = () => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: editablePlanEntities,
      },
    }));

    setPlanAction?.(planAction);

    openEditMode();
  };

  return (
    <>
      <StickyBar css={{ backgroundColor: "transparent" }}>
        <Row>
          <Col>
            <Box boxStyle="lightGrey" mb={theme.spacing.spacing06}>
              <FlexContainer justifyContent="flex-end">
                <If condition={isEditMode}>
                  <Then>
                    <FlexContainer>
                      <Button
                        form="planSettingsForm"
                        icon="save"
                        onClick={handleSavePlan}
                      >
                        Save
                      </Button>
                      <Button
                        severity="low"
                        ml={theme.spacing.spacing04}
                        icon="close"
                        onClick={open}
                      />
                    </FlexContainer>
                  </Then>
                  <Else>
                    <Button icon="edit" onClick={handleEditMode}>
                      Edit Plan
                    </Button>
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </StickyBar>
      <Modal
        onClose={close}
        isOpen={isOpen}
        contentLabel={"Unsaved changes"}
        actions={[
          {
            order: 0,
            onAction: handleDiscardChanges,
            label: "Discard Changes",
            severity: "low",
          },
          {
            order: 1,
            onAction: handleSaveChanges,
            label: "Save",
            severity: "high",
          },
        ]}
      >
        There could be unsaved changes!
      </Modal>
    </>
  );
}
