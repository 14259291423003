/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DraggableProvided } from "react-beautiful-dnd";
import styled from "styled-components";
import { ExtendedTooltip, theme } from "@nordcloud/gnui";
import { ToggleItemBox } from "~/components";
import { PlanEntity, SelectedPlanEntity } from "../../types";

type Props = {
  item: PlanEntity;
  onMouseDown: (item: SelectedPlanEntity) => void;
  isCheckbox?: boolean;
  isSelected?: boolean;
  onDelete?: () => void;
  active?: boolean;
  noDesc?: boolean;
  provided?: DraggableProvided;
  disabled?: boolean;
  tooltip?: string;
};

export function ActionItemEdit({
  item,
  onMouseDown,
  isCheckbox,
  isSelected,
  onDelete,
  active,
  noDesc,
  provided,
  disabled,
  tooltip,
}: Props) {
  const description = noDesc ? "" : item.description || "";

  return (
    <Wrap>
      <ExtendedTooltip
        caption={tooltip}
        position="start"
        zIndex={theme.zindex.topoftheworld}
      >
        <ToggleItemBox
          active={active}
          provided={provided}
          isSelected={isSelected}
          isCheckbox={isCheckbox}
          item={{
            id: item.id ?? "",
            color: item.color ?? "",
            name: item.name ?? "",
            actionType: item.actionType,
            description,
            icon: item.icon,
            onMouseDown: () => onMouseDown(item),
            onDelete: onDelete,
          }}
          disabled={disabled}
        />
      </ExtendedTooltip>
    </Wrap>
  );
}
// workaround to make inline-block for tooltip text
// should probably be implemented in GNUI
export const Wrap = styled.div`
  & div:first-child {
    display: flex;
    flex: 1;
  }
`;
