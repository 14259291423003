/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ExecutionPolicy,
  PlanAction,
  PlanActionBatch,
} from "~/generated/graphql";
import { sortItem } from "~/tools";
import { PlanActionTypeExtended } from "../../PlanProvider";

export const mapPlanActionToPlanEntity = (item: PlanAction) => {
  const {
    __typename: IgnoredTypename,
    resourceGroups,
    windowDuration,
    inputParameters,
    notificationGroups,
    name,
    ...rest
  } = item;

  return {
    windowDuration: windowDuration.toString(),
    inputParameters: inputParameters ?? [],
    ...rest,

    id: item.id ?? "",
    name: name,
    actionName: item.action.name,
    listId: item.id,
    resourceGroupIds: resourceGroups?.map((group) => group.id),
    notificationGroups: notificationGroups?.map((group) => ({
      ...group,
      id: group.id ?? "",
    })),
    batchActions: undefined,
    color: item.action.color ?? "",
  };
};

export const mapPlanActionBatchToPlanEntity = (item: PlanActionBatch) => {
  const { __typename: IgnoredTypename, windowDuration, ...rest } = item;

  return {
    windowDuration: windowDuration.toString(),
    ...rest,
    action: undefined,
    batchName: item.name,
    name: item.name,
    listId: item.id ?? "",
    id: item.id ?? "",
    runInSequence: false,
    batchActions:
      item.batchActions?.map((batchAction) => {
        const {
          __typename: IgnoredTypenameBatchAction,
          inputParameters,
          ...batchActionRest
        } = batchAction;
        return {
          windowDuration: windowDuration.toString(),
          ...batchActionRest,
          action: {
            ...batchAction.action,
          },
          name: batchAction.action.name,
          listId: batchAction.id ?? "",
          id: batchAction.id ?? "",
          resourceGroupIds: batchAction.resourceGroups?.map(
            (group) => group.id
          ),
          notificationGroups: batchAction.notificationGroups?.map((group) => ({
            ...group,
            id: group.id ?? "",
          })),
          inputParameters: inputParameters ?? [],
          executionPolicy: ExecutionPolicy.Anyway,
          runInSequence: false,
          color: batchAction.action.color ?? "",
        };
      }) ?? [],
  };
};

export const sortPlanEntitiesByOrder = (
  planEntities: PlanActionTypeExtended[]
) => {
  return sortItem(planEntities, (a, b) => (a.order ?? 0) - (b.order ?? 0));
};
