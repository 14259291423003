/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldErrors } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

export const Wrap = styled(FlexContainer)`
  position: relative;
  width: 100%;
  margin-bottom: ${theme.spacing.spacing05};
  gap: ${theme.spacing.spacing04};
  > div {
    width: 100%;
  }
`;
const TextWrap = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(83%);
`;

type Props = {
  error?: FieldErrors;
};

export function ErrorText({ error }: Props) {
  return (
    <When condition={error !== undefined}>
      <TextWrap>
        <Text size="sm" color="danger" tag="span">
          {error?.message?.toString()}
        </Text>
      </TextWrap>
    </When>
  );
}

export const ActionBatchLabel = styled.div`
  font-size: ${theme.fontSizes.xs};
  color: white;
  position: relative;
  top: -${theme.spacing.spacing02};
  background-color: ${theme.color.text.text03};
  width: fit-content;
  height: ${theme.fontSizes.xs};
  padding-left: ${theme.spacing.spacing02};
  padding-right: ${theme.spacing.spacing02};
  padding-top: ${theme.spacing.spacing01};
  padding-bottom: ${theme.spacing.spacing01};
  border-radius: ${theme.radius.xl};
  display: flex;
  align-items: center;
`;

export const ActionBatchLabelSummary = styled.div`
  font-size: ${theme.fontSizes.md};
  color: white;
  background-color: ${theme.color.text.text03};
  padding-left: ${theme.spacing.spacing03};
  padding-right: ${theme.spacing.spacing03};
  width: fit-content;
  height: fit-content;
  border-radius: ${theme.radius.xl};
  position: relative;
  top: -${theme.spacing.spacing00};
  left: ${theme.spacing.spacing02};
`;

export const ActionBatchSummaryWrap = styled.div``;

export const ActionItemWrap = styled.div`
  padding-left: ${theme.spacing.spacing02};
  padding-right: ${theme.spacing.spacing03};
`;

type ActionWrapProps = {
  active?: boolean;
};

export const ActionBatchWrap = styled.div<ActionWrapProps>`
  padding: ${theme.spacing.spacing00} ${theme.spacing.spacing02}
    ${theme.spacing.spacing01};
  border: 1px solid ${theme.color.border.border01};
  background-color: ${({ active }) =>
    active ? theme.color.background.ui04 : theme.color.background.ui03};
  border-color: ${({ active }) => (active ? theme.color.background.ui05 : "")};
  width: calc(100% - ${theme.spacing.spacing05});
  border-radius: ${theme.radius.md};
  transition: ${theme.transition};

  &:hover {
    background-color: ${theme.color.background.ui04};
  }
`;
export const ActionBatchHeader = styled(FlexContainer)`
  padding-left: ${theme.spacing.spacing06};
  margin-bottom: ${theme.spacing.spacing01};
  margin-top: ${theme.spacing.spacing00};
  cursor: pointer;
`;

export const WrapMax = styled.div`
  width: 100%;
`;
