/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ToggleItemBox } from "~/components";
import { Detail } from "~/components/Details/Detail";
import { DetailPlanActionName } from "../../components/presets";
import { PlanActionTypeExtended, usePlan } from "../../PlanProvider";

export function ActionItem({
  entity,
  onMouseDown,
}: {
  entity: PlanActionTypeExtended;
  onMouseDown: () => void;
}) {
  const { planAction } = usePlan();

  return (
    <ToggleItemBox
      item={{
        id: entity.id ?? "",
        color: entity?.action?.color ?? "",
        name: (
          <Detail
            detail={DetailPlanActionName({
              name: entity.name ?? "",
              id: entity.id ?? "",
            })}
            customCss={{ marginBottom: 0 }}
            editButtonSize="sm"
          />
        ),
        onMouseDown,
      }}
      active={planAction?.listId === entity.listId}
    />
  );
}
