/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";
import { SelectedAction, SelectedPlanEntity } from "../types";
import { ResourceGroupSummary } from "./ResourceGroupsSummary";

export function PlanActionBatchSummary({
  batch,
}: {
  batch: SelectedPlanEntity;
  batchActions?: SelectedAction[];
}) {
  return (
    <>
      {batch.batchActions?.map((action, idx) => (
        <Table.tr key={`${action.listId}-${idx}`}>
          <Table.td>
            <ActionRow>{action.name}</ActionRow>
          </Table.td>
          <Table.td css={{ paddingBottom: "5px", paddingLeft: "0" }}>
            <ResourceGroupSummary action={action} />
          </Table.td>
        </Table.tr>
      ))}
    </>
  );
}

const ActionRow = styled.div`
  margin-left: ${theme.spacing.spacing03};
  margin-bottom: ${theme.spacing.spacing02};
`;
