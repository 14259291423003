/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Action,
  Plan,
  PlanAction,
  PlanActionType,
  SystemAction,
} from "~/generated/graphql";
import { createCtx } from "~/tools";
import {
  NotificationGroupItem,
  SelectedPlanEntity,
  TimeUnits,
} from "../PlanCreate/components/PlanCreateWizard/types";

type ActionType = Action & { version?: string };

export type PlanExtended = Omit<Plan, "planAction"> & {
  planAction?: SelectedPlanEntity;
};

export type PlanActionTypeExtended = SelectedPlanEntity & {
  resourceGroups?: PlanAction["resourceGroups"];
  action?: ActionType | SystemAction;
  actionType?: PlanActionType;
  resourceGroupIds?: string[];
  notificationGroups?: NotificationGroupItem[] | null;
  unit?: TimeUnits;
  color?: string;
  order?: number;
};

type Props = {
  plan?: Partial<PlanExtended>;
  planAction?: PlanActionTypeExtended;
  setPlanAction?: (planAction?: PlanActionTypeExtended) => void;
  refetch?: () => void;
};

export const [usePlan, PlanCtxProvider] = createCtx<Props>();
