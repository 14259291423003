/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import {
  Box,
  Button,
  Dropdown,
  FlexContainer,
  Input,
  Label,
  Select,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  PlanActionNotificationGroupScope,
  ActionScope,
} from "~/generated/graphql";
import { FormGroup } from "~/components";
import { isAdvanceTrigger } from "~/views/plans/utils";
import { NotificationGroupSidebar } from "../NotificationGroupSidebar";
import { PlanField } from "../PlanCreateWizard/constants";
import { usePlanWizard } from "../PlanCreateWizard/PlanProvider";
import { units } from "../PlanCreateWizard/PlanSettingsForms/components/ActionSettingsForm/options";
import {
  convertStringToTimeUnit,
  updateEntities,
} from "../PlanCreateWizard/PlanSettingsForms/components/utils";
import { useStepState } from "../PlanCreateWizard/PlanSettingsForms/StepProvider";
import {
  FormField,
  NotificationGroupItem,
  Option,
  SelectedPlanEntity,
} from "../PlanCreateWizard/types";
import {
  optionsScope,
  optionsTriggerResource,
  optionsTriggerAction,
} from "./constants";

type Props = {
  remove: () => void;
  index: number;
  handlePlanErrors: (toValidate: NotificationGroupItem[]) => void;
};

export function NotificationItem({ remove, index, handlePlanErrors }: Props) {
  const { watch, setValue, control, formState, register, trigger, reset } =
    useFormContext();

  const error = formState.errors[`notificationGroups`] as
    | FieldValues[]
    | undefined;

  const scope = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.SCOPE]}`
  );
  const triggerEvent = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.TRIGGER_EVENT]}`
  );

  const notificationGroupsIds = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
      FormField.NOTIFICATION_GROUPS_IDS,
    ]}`
  );
  const notificationGroups = watch(FormField.NOTIFICATION_GROUPS);

  const { stepState, updateStepState } = useStepState();

  const selectedEntity = stepState.selectedEntity;

  const actionScope = selectedEntity?.scope;

  const scopeOptions =
    actionScope === ActionScope.Plan
      ? optionsScope.filter(
          (i) => i.value === PlanActionNotificationGroupScope.Action
        )
      : optionsScope;

  const triggerTypeOptions =
    scope === PlanActionNotificationGroupScope.Action
      ? optionsTriggerAction
      : optionsTriggerResource;

  const { setPlanData } = usePlanWizard();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    notificationGroupsIds ?? []
  );

  const updateSelectedEntity = (updateObject: Partial<SelectedPlanEntity>) => {
    const updatedEntities = updateEntities(stepState, updateObject);
    updateStepState({
      entities: updatedEntities,
      selectedEntity: { ...selectedEntity, ...updateObject },
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: updatedEntities,
      },
    }));
  };

  const handleChange = (
    name: FormField[],
    value?: string | string[] | number
  ) => {
    const field = `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[name]}`;
    setValue(field, value);

    trigger();
    updateSelectedEntity({
      [FormField.NOTIFICATION_GROUPS]: [...notificationGroups],
    });
    handlePlanErrors(notificationGroups);
  };

  const handleRemove = (toRemoveIdx: number) => {
    const filteredNotificationGroups = notificationGroups?.filter(
      (_: NotificationGroupItem, idx: number) => {
        return idx !== toRemoveIdx;
      }
    );
    reset({
      [FormField.NOTIFICATION_GROUPS]: filteredNotificationGroups,
    });
    remove();
    updateSelectedEntity({
      [FormField.NOTIFICATION_GROUPS]: [...filteredNotificationGroups],
    });
    handlePlanErrors(filteredNotificationGroups);
  };

  function handleScopeChangeWithDefaultValue(value: string) {
    handleChange([FormField.SCOPE], value);
    return (val: string) => {
      const defaultValue =
        val === PlanActionNotificationGroupScope.Action
          ? optionsTriggerAction
          : optionsTriggerResource;
      return handleChange([FormField.TRIGGER_EVENT], defaultValue[0].value);
    };
  }
  useEffect(() => {
    trigger();
  }, [selectedEntity, trigger]);

  const notificationCount = index + 1;

  return (
    <Box
      data-testid={`box-notification`}
      mb={theme.spacing.spacing02}
      boxStyle="lightGrey"
    >
      <Headline>
        <Text tag="div" weight="medium">
          Notification #{notificationCount}
        </Text>
        <Button
          data-testid={`button-delete-notification`}
          severity="low"
          ml="auto"
          icon="trash"
          onClick={() => handleRemove(index)}
        />
      </Headline>
      <div css={{ marginTop: theme.spacing.spacing04 }}>
        <FormGroup error={error?.[index]?.scope}>
          <Label name="Subject" />
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
              FormField.SCOPE,
            ]}`}
            render={() => {
              return (
                <Select
                  placeholder="Select Subject"
                  options={scopeOptions}
                  onChange={(option: Option | null) =>
                    handleScopeChangeWithDefaultValue(option?.value ?? "")(
                      option?.value ?? ""
                    )
                  }
                  value={scopeOptions.find((i) => i.value === scope)}
                  isSearchable={false}
                />
              );
            }}
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={error?.[index]?.triggerEvent}>
          <Label name="Condition" htmlFor={`${index}`} />
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
              FormField.TRIGGER_EVENT,
            ]}`}
            render={() => {
              return (
                <Select
                  aria-label="Example Label"
                  data-testid="select-condition"
                  placeholder="Select Condition"
                  inputId={`${index}`}
                  isDisabled={!scope}
                  options={triggerTypeOptions}
                  onChange={(option: Option | null) => {
                    handleChange([FormField.TRIGGER_EVENT], option?.value);
                  }}
                  value={triggerTypeOptions.find(
                    (i) => i.value === triggerEvent
                  )}
                />
              );
            }}
          />
        </FormGroup>
        <When condition={isAdvanceTrigger(triggerEvent)}>
          <Label
            css={{ marginBottom: "0" }}
            htmlFor="inAdvance"
            name="In Advance"
          />
          <Text mb={theme.spacing.spacing02} size="sm" tag="div">
            Provide how much notice you want before action starts
          </Text>
          <FormGroup
            error={error?.[index]?.inAdvance}
            customCss={{ position: "relative" }}
          >
            <FlexContainer
              mb={theme.spacing.spacing04}
              gap={theme.spacing.spacing03}
              alignItems="baseline"
              css={{ height: theme.spacing.spacing08 }}
            >
              <div css={{ width: "10rem", marginBottom: "0" }}>
                <Controller
                  control={control}
                  name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                    FormField.IN_ADVANCE,
                  ]}`}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        data-testid={"input-in-advance"}
                        type="number"
                        value={field.value}
                        {...register(
                          `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                            FormField.IN_ADVANCE,
                          ]}`
                        )}
                        onBlur={(e) => {
                          handleChange(
                            [FormField.IN_ADVANCE],
                            Number(e.target.value)
                          );
                        }}
                      />
                    );
                  }}
                />
              </div>

              <FormGroup>
                <div css={{ width: "10rem" }}>
                  <Controller
                    control={control}
                    name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                      FormField.IN_ADVANCE_UNIT,
                    ]}`}
                    render={({ field: { value } }) => {
                      const handleUnitChange = (val: string) => {
                        const timeUnit = convertStringToTimeUnit(val);
                        handleChange([FormField.IN_ADVANCE_UNIT], timeUnit);
                      };

                      return (
                        <Dropdown
                          name="Select Unit"
                          options={units}
                          onChange={handleUnitChange}
                          value={value}
                          minNumOfOptionsToShowSearchBox={10}
                        />
                      );
                    }}
                  />
                </div>
              </FormGroup>
            </FlexContainer>
          </FormGroup>
        </When>

        <FormGroup error={error?.[index]?.notificationGroupIds}>
          <NotificationGroupSidebar
            onSubmit={(ids) => {
              handleChange([FormField.NOTIFICATION_GROUPS_IDS], ids);
            }}
            onClear={() => {
              return handleChange(
                [FormField.NOTIFICATION_GROUPS_IDS],
                undefined
              );
            }}
            {...{ selectedOptions, setSelectedOptions }}
          />
        </FormGroup>
      </div>
    </Box>
  );
}

const Headline = styled(FlexContainer)`
  border-bottom: ${theme.borders.grey};
  margin: -${theme.spacing.spacing03} -${theme.spacing.spacing03} 0;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03}
    ${theme.spacing.spacing02};
`;
