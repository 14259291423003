/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { Else, If, Then } from "react-if";
import { isNonEmpty } from "~/tools";
import { usePlanWizard } from "../PlanProvider";
import { StepState } from "../types";
import { ActionsView, NoActionView } from "./components";
import { AddActionWrapper } from "./components/AddAction/AddActionWrapper";
import { StepStateCtxProvider } from "./StepProvider";

type Props = {
  nextStep?: () => void;
};

export function PlanSettingsForm({ nextStep }: Props) {
  const { planData, selectedEntity } = usePlanWizard();

  const planDataEntities = planData?.plan_settings?.planEntities ?? [];

  const flatWithBatches = planDataEntities.flatMap((entity) => {
    if (entity.batchActions) {
      return [entity, ...entity.batchActions];
    }
    return entity;
  });

  const selectedEntityDefault =
    flatWithBatches.find((entity) => entity.listId === selectedEntity) ??
    flatWithBatches[0];

  const [stepState, updateStepState] = useReducer(
    (data: StepState, partialData: Partial<StepState>) => ({
      ...data,
      ...partialData,
    }),
    {
      entities: planDataEntities,
      selectedEntity: selectedEntityDefault,
    }
  );
  const areEntities = isNonEmpty(stepState.entities);

  return (
    <StepStateCtxProvider value={{ stepState, updateStepState }}>
      <If condition={areEntities}>
        <Then>
          <ActionsView nextStep={nextStep} />
        </Then>
        <Else>
          <NoActionView addAction={<AddActionWrapper />} />
        </Else>
      </If>
    </StepStateCtxProvider>
  );
}
