/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Case, Else, If, Switch, Then } from "react-if";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { useDisclosure } from "~/hooks";
import { isEmpty, isNil, isNotEmpty, isNotNil } from "~/tools";
import { ActionBatch } from "~/views/plans/components/ActionBatch";
import { PlanData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { PlanWizardCtxProvider } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { PlanSettingsForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/PlanSettingsForm";
import { ActionItemWrap, WrapMax } from "~/views/plans/styled";
import { ScheduleType } from "~/views/plans/types";
import { usePlan } from "../../PlanProvider";
import { ActionItem } from "./ActionItem";
import { ActionBox } from "./components/ActionBox/ActionBox";
import { DetailsTabBar } from "./DetailsTabBar";
import {
  mapPlanActionBatchToPlanEntity,
  mapPlanActionToPlanEntity,
  sortPlanEntitiesByOrder,
} from "./utils";

export function DetailsTab() {
  const { open, close, isOpen } = useDisclosure();
  const { plan, planAction, setPlanAction } = usePlan();
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>();

  const planActions = useMemo(() => {
    return plan?.planActions ?? [];
  }, [plan]);

  const planActionBatches = useMemo(() => {
    return plan?.planActionBatches ?? [];
  }, [plan]);

  const editablePlanActions = useMemo(() => {
    return planActions.map(mapPlanActionToPlanEntity) ?? [];
  }, [planActions]);

  const editablePlanActionBatches = useMemo(() => {
    return planActionBatches.map(mapPlanActionBatchToPlanEntity) ?? [];
  }, [planActionBatches]);

  const planEntities = sortPlanEntitiesByOrder(
    [...editablePlanActions, ...editablePlanActionBatches].filter(isNotNil)
  );

  const [planData, setPlanData] = useState<PlanData>({
    details: undefined,
    schedule_plan: undefined,
    schedule_type: ScheduleType.recurring,
    general_notifications: undefined,
    plan_status: undefined,
    plan_settings: {
      planEntities: planEntities,
    },
  });

  const flatEntities = planEntities.flatMap((entity) => {
    if (entity.batchActions) {
      return [entity, ...entity.batchActions];
    }
    return entity;
  });

  const foundEntity = flatEntities.find(
    (entity) => entity.listId === selectedEntity
  );

  useEffect(() => {
    if (isNil(foundEntity) && isNil(selectedEntity)) {
      setSelectedEntity?.(flatEntities[0]?.listId);
    }
    setPlanAction?.(foundEntity);
  }, [flatEntities, foundEntity, selectedEntity, setPlanAction]);

  return (
    <PlanWizardCtxProvider
      value={{
        planData,
        setPlanData,
        setSelectedEntity,
        selectedEntity,
      }}
    >
      <Box innerSpacing="spacing00">
        <DetailsTabBar
          openEditMode={open}
          closeEditMode={close}
          isEditMode={isOpen}
        />
        <Switch>
          <Case condition={isOpen}>
            <PlanSettingsForm />
          </Case>
          <Case condition={!isOpen && isNotEmpty(planEntities)}>
            <Row>
              <Col data-testid="actions-list" xs={4} sm={8} md={2} lg={3}>
                <FlexContainer direction="column" justifyContent="flex-start">
                  <Text width="100%" align="left">
                    Actions
                  </Text>
                  {planEntities?.map((entity) => (
                    <If
                      key={entity.listId}
                      condition={isNotEmpty(entity.batchActions ?? [])}
                    >
                      <Then>
                        <WrapMax
                          css={{ paddingTop: `${theme.spacing.spacing03}` }}
                        >
                          <ActionBatch
                            name={entity.name}
                            onClick={() => {
                              setSelectedEntity?.(entity.listId);
                            }}
                            active={planAction?.listId === entity.listId}
                          >
                            {entity.batchActions?.map((batchAction) => {
                              return (
                                <ActionItem
                                  key={batchAction.listId}
                                  entity={batchAction}
                                  onMouseDown={() =>
                                    setSelectedEntity?.(batchAction.listId)
                                  }
                                />
                              );
                            })}
                          </ActionBatch>
                        </WrapMax>
                      </Then>
                      <Else>
                        <WrapMax>
                          <ActionItemWrap>
                            <ActionItem
                              key={entity.listId}
                              entity={entity}
                              onMouseDown={() => {
                                setSelectedEntity?.(entity.listId);
                              }}
                            />
                          </ActionItemWrap>
                        </WrapMax>
                      </Else>
                    </If>
                  ))}
                </FlexContainer>
              </Col>
              <Col data-testid="action-details" xs={4} sm={8} md={6} lg={9}>
                <Box boxStyle="grey" innerSpacing="spacing04">
                  <FlexContainer alignItems="flex-start">
                    <ActionBox />
                  </FlexContainer>
                </Box>
              </Col>
            </Row>
          </Case>
          <Case condition={!isOpen && isEmpty(planEntities)}>
            <NoData hasIcon message="There are no Actions" />
          </Case>
        </Switch>
      </Box>
    </PlanWizardCtxProvider>
  );
}
