/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { When } from "react-if";
import { theme } from "@nordcloud/gnui";
import { ActionScope } from "~/generated/graphql";
import { isEmpty } from "~/tools";
import { useGetAllResourceGroups } from "~/views/resourceGroups/hooks";
import { SelectedAction } from "../types";

export function ResourceGroupSummary({ action }: { action: SelectedAction }) {
  const { getGroups, data } = useGetAllResourceGroups();
  const resourceGroups = data
    .filter((groups) => action?.resourceGroupIds?.includes(groups.id))
    .map(({ name }) => name);

  useEffect(() => {
    getGroups();
  }, [getGroups]);
  return (
    <>
      {isEmpty(resourceGroups) && action.scope !== ActionScope.Plan ? (
        <div css={{ color: theme.color.text.text03 }}>
          Resource groups not assigned
        </div>
      ) : (
        resourceGroups?.map((name, index) => {
          return `${index ? "," : ""} ${name}`;
        })
      )}
      <When condition={action.scope === ActionScope.Plan}>
        <div css={{ color: theme.color.text.text03 }}>
          There are no resource groups for Plan based action scope
        </div>
      </When>
    </>
  );
}
