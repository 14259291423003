/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box, FlexContainer, Spacer, Text, theme } from "@nordcloud/gnui";
import { SelectedPlanEntity } from "../../types";
import { useStepState } from "../StepProvider";
import { ActionView } from "./ActionView";
import { AddActionWrapper } from "./AddAction";
import { DraggablePlanEntities } from "./DraggablePlanEntities/DraggablePlanEntities";

type Props = {
  nextStep?: () => void;
};

export function ActionsView({ nextStep }: Props) {
  const { stepState } = useStepState();

  const entities = stepState.entities?.map(mapEntity) ?? [];
  return (
    <Row>
      <Col data-testid="actions-list" xs={4} sm={8} md={2} lg={3}>
        <FlexContainer direction="column" justifyContent="flex-start">
          <Text width="100%" align="left">
            Actions
          </Text>
          <DraggablePlanEntities entities={entities} nextStep={nextStep} />
          <Spacer height={theme.spacing.spacing02} />
          <AddActionWrapper severity="low" />
        </FlexContainer>
      </Col>
      <Col data-testid="action-details" xs={4} sm={8} md={6} lg={9}>
        <Box boxStyle="grey" innerSpacing="spacing04">
          <FlexContainer alignItems="stretch" gap={theme.spacing.spacing04}>
            <ActionView {...{ nextStep }} />
          </FlexContainer>
        </Box>
      </Col>
    </Row>
  );
}

const mapEntity = (entity: SelectedPlanEntity) => ({
  ...entity,
  id: entity.id ?? "",
  batchId: entity.batchId ?? "",
  listId: entity.listId ?? "",
  name: entity.name,
  description: "",
  color: entity.color ?? "",
  actionType: entity.actionType,
});
