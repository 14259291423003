/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { getDroppableCss } from "~/components/Styled";
import { reorder, useComponentsSelector } from "~/hooks";
import { isEmpty } from "~/tools";
import { Item } from "~/types";
import { PlanField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { PlanEntity, SelectedPlanEntity } from "../../../types";
import { DraggableBatchActionsList } from "./DraggableBatchActionsList";

type Props = {
  actions: Item[];
  actionBatch?: PlanEntity;
  nextStep?: () => void;
};

export function DraggableBatchActions({ actionBatch, actions }: Props) {
  const { items, handleDragEnd, handleDelete, handleShowSearch } =
    useComponentsSelector([]);
  const { stepState, updateStepState } = useStepState();
  const { setPlanData, setSelectedEntity } = usePlanWizard();

  useEffect(() => {
    handleShowSearch(actions);
  }, [actions, handleShowSearch]);

  const setNewPlanEntities = (newEntities: SelectedPlanEntity[]) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: newEntities,
      },
    }));
  };

  const handleDrag = (result: DropResult) => {
    handleDragEnd(result);

    const reorderedBatchActions = reorder(
      actionBatch?.batchActions ?? [],
      result.source.index,
      result.destination?.index ?? -1
    );

    const updatedEntities = stepState.entities?.map((entity) => {
      if (entity.listId === actionBatch?.listId) {
        return {
          ...entity,
          batchActions: reorderedBatchActions,
        };
      }
      return entity;
    });
    updateStepState({
      entities: updatedEntities,
    });
    setNewPlanEntities(updatedEntities ?? []);
  };

  const handleDeleteActions = (item: PlanEntity) => {
    const filteredBatchActions =
      actionBatch?.batchActions?.filter((ab) => ab.listId !== item.listId) ??
      [];

    const isEmptyBatch = filteredBatchActions?.length === 0;

    const indexOfSelectedBatchAction = actions.findIndex(
      (entity) => entity.listId === item.listId
    );

    const indexOfSelectedEntity = stepState.entities.findIndex(
      (entity) => entity.listId === actionBatch?.listId
    );

    const selectedEntityAfterDelete = isEmptyBatch
      ? stepState.entities[Math.max(indexOfSelectedEntity - 1, 0)]
      : filteredBatchActions[
          indexOfSelectedBatchAction
            ? Math.max(indexOfSelectedBatchAction - 1, 0)
            : 0
        ];

    const updatedEntities = isEmptyBatch
      ? stepState.entities?.filter(
          (entity) => entity.listId !== actionBatch?.listId
        )
      : stepState.entities?.map((entity) => {
          if (entity.listId === actionBatch?.listId) {
            return {
              ...entity,
              batchActions: filteredBatchActions,
            };
          }
          return entity;
        });

    updateStepState({
      entities: updatedEntities,
      selectedEntity: selectedEntityAfterDelete,
    });

    setNewPlanEntities(updatedEntities ?? []);
    setSelectedEntity?.(selectedEntityAfterDelete.listId);
    handleDelete(item);
  };

  if (isEmpty(items.SELECTED_ITEMS)) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={"SELECTED_ITEMS"}>
        {(providedInner, snapshot) => {
          return (
            <div
              css={getDroppableCss(true, snapshot.isDraggingOver)}
              ref={providedInner.innerRef}
            >
              <DraggableBatchActionsList
                items={items["SELECTED_ITEMS"]}
                onDelete={handleDeleteActions}
                batchId={actionBatch?.listId ?? ""}
              />
              {providedInner.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
