/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { getDroppableCss } from "~/components/Styled";
import { reorder, useComponentsSelector } from "~/hooks";
import { isEmpty } from "~/tools";
import { PlanField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { PlanEntity } from "../../../types";
import { DraggablePlanEntitiesList } from "./DraggablePlanEntitiesList";

type Props = {
  entities: PlanEntity[];
  nextStep?: () => void;
};

export function DraggablePlanEntities({ entities, nextStep }: Props) {
  const { items, handleDragEnd, handleDelete, handleShowSearch } =
    useComponentsSelector([]);
  const { updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  useEffect(() => {
    if (entities) {
      handleShowSearch(entities);
    }
  }, [entities, handleShowSearch]);

  const setNewPlanEntities = (newEntities: PlanEntity[]) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: newEntities,
      },
    }));
  };

  const handleDrag = (result: DropResult) => {
    handleDragEnd(result);
    const newEntities = reorder(
      entities ?? [],
      result.source.index,
      result.destination?.index ?? -1
    );

    updateStepState({
      entities: newEntities,
    });

    setNewPlanEntities(newEntities);
  };

  const handleDeleteEntity = (item: PlanEntity) => {
    handleDelete(item);
  };

  if (isEmpty(items.SELECTED_ITEMS)) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={"SELECTED_ITEMS"}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              css={getDroppableCss(true, snapshot.isDraggingOver)}
            >
              <DraggablePlanEntitiesList
                items={items["SELECTED_ITEMS"]}
                onDelete={handleDeleteEntity}
                nextStep={nextStep}
              />
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
