/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import {
  Switch as SwitchComponent,
  Case,
  When,
  If,
  Then,
  Else,
} from "react-if";
import { useHistory } from "react-router-dom";
import {
  Heading,
  Message,
  Spacer,
  SVGIcon,
  Box,
  Text,
  FlexContainer,
  Button,
  Table,
  theme,
  Switch,
} from "@nordcloud/gnui";
import { DataDisplayWrapper, InfoCard } from "~/components";
import { ROUTES } from "~/routing/routes";
import { isEmpty, isNonEmpty, isNotNil } from "~/tools";
import { ActionBatchLabelSummary } from "~/views/plans/styled";
import { ScheduleType } from "~/views/plans/types";
import { defaultValues, PlanField } from "./constants";
import { usePlanWizard } from "./PlanProvider";
import { PlanActionBatchSummary } from "./PlanSummary";
import { PlanActionSummary } from "./PlanSummary/PlanActionSummary";
import { State, TimeUnits } from "./types";

type Props = {
  planId: string;
  state: State;
  updateState: Dispatch<Partial<State>>;
  step: number;
};

export function ConfirmAndFinalize({
  planId,
  state,
  updateState,
  step,
}: Props) {
  const history = useHistory();

  const { planData, setPlanData } = usePlanWizard();
  const onAddNewPlan = () => {
    updateState({ currentStep: 0, subStep: 0 });
    setPlanData(defaultValues);
  };

  return (
    <>
      <When condition={step === 0}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Heading marginBottom="spacing04" level={4}>
              Plan Status
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer
                grow={1}
                alignItems="start"
                justifyContent="space-between"
              >
                <Switch
                  labelText="Active"
                  checked={state.isPlanActive}
                  onChange={(event) => {
                    setPlanData((prevPlanData) => ({
                      ...prevPlanData,
                      [PlanField.PLAN_STATUS]: {
                        planEnabled: event.target.checked,
                      },
                    }));

                    updateState({ isPlanActive: event.target.checked });
                  }}
                />
              </FlexContainer>
            </Box>
            <Heading marginBottom="spacing04" level={4}>
              Plan Details
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <div css={{ flex: "1" }}>
                  <Text size="sm" mb={0}>
                    Plan Name
                  </Text>
                  <DataDisplayWrapper
                    hasData={planData?.details?.name !== undefined}
                  >
                    {planData?.details?.name}
                  </DataDisplayWrapper>
                  <Spacer height={theme.spacing.spacing04} />
                  <Text size="sm" mb={0}>
                    Description
                  </Text>
                  <DataDisplayWrapper
                    hasData={!isEmpty(planData?.details?.description ?? "")}
                  >
                    {planData?.details?.description}
                  </DataDisplayWrapper>
                  <Spacer height={theme.spacing.spacing04} />
                  <Text size="sm" mb={0}>
                    Metadata
                  </Text>
                  <FlexContainer
                    alignItems="start"
                    justifyContent="space-between"
                  >
                    <FlexContainer grow={1}>
                      <Text size="sm" mb={0} />
                      <DataDisplayWrapper
                        hasData={isNonEmpty(planData?.details?.metadata)}
                      >
                        <Table>
                          <Table.thead>
                            <Table.tr>
                              <Table.th>
                                <Text color={theme.color.text.text02} size="sm">
                                  Key
                                </Text>
                              </Table.th>
                              <Table.th>
                                <Text color={theme.color.text.text02} size="sm">
                                  Value
                                </Text>
                              </Table.th>
                            </Table.tr>
                          </Table.thead>
                          <Table.tbody>
                            {planData?.details?.metadata?.map(
                              ({ key, value }) => {
                                return (
                                  <Table.tr key={`${key}${value}`}>
                                    <Table.td>{key}</Table.td>
                                    <Table.td>{value}</Table.td>
                                  </Table.tr>
                                );
                              }
                            )}
                          </Table.tbody>
                        </Table>
                      </DataDisplayWrapper>
                    </FlexContainer>
                  </FlexContainer>
                </div>
                <div>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={() => updateState({ currentStep: 0, subStep: 0 })}
                  />
                </div>
              </FlexContainer>
            </Box>
            <Heading marginBottom="spacing04" level={4}>
              Plan Settings
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <FlexContainer grow={1}>
                  <DataDisplayWrapper
                    hasData={
                      planData?.plan_settings?.planEntities !== undefined
                    }
                  >
                    <Table>
                      <Table.thead>
                        <Table.tr
                          css={{
                            borderBottom: `4px solid ${theme.color.border.border01}`,
                          }}
                        >
                          <Table.th>
                            <Text color={theme.color.text.text02} size="sm">
                              Actions
                            </Text>
                          </Table.th>
                          <Table.th>
                            <Text color={theme.color.text.text02} size="sm">
                              Resource Groups
                            </Text>
                          </Table.th>
                        </Table.tr>
                      </Table.thead>

                      {planData?.plan_settings?.planEntities?.map((action) => {
                        const isBatchAction = isNotNil(action.batchActions);
                        return (
                          <If condition={isBatchAction} key={action.listId}>
                            <Then>
                              <Table.tbody
                                css={{
                                  border: `2px dotted ${theme.color.border.border02}`,
                                }}
                                key={action.listId}
                              >
                                <Table.tr>
                                  <Table.td>
                                    <ActionBatchLabelSummary>{`Action Batch: ${action.batchName}`}</ActionBatchLabelSummary>
                                  </Table.td>
                                </Table.tr>
                                <PlanActionBatchSummary
                                  key={`${action.listId}-summary`}
                                  batch={action}
                                />
                              </Table.tbody>
                            </Then>
                            <Else>
                              <Table.tbody>
                                <Table.tr>
                                  <PlanActionSummary
                                    action={action}
                                    key={action.listId}
                                  />
                                </Table.tr>
                              </Table.tbody>
                            </Else>
                          </If>
                        );
                      })}
                    </Table>
                  </DataDisplayWrapper>
                </FlexContainer>
                <div>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={() => updateState({ currentStep: 2, subStep: 0 })}
                  />
                </div>
              </FlexContainer>
            </Box>
            <Heading marginBottom="spacing04" level={4}>
              Plan Scheduled
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <div>
                  <Text size="sm" mb={0}>
                    Timezone
                  </Text>
                  <DataDisplayWrapper
                    hasData={
                      planData?.schedule_plan?.scheduleTimezone !== undefined
                    }
                  >
                    {planData?.schedule_plan?.scheduleTimezone}
                  </DataDisplayWrapper>
                  <SwitchComponent>
                    <Case
                      condition={
                        planData?.schedule_type === ScheduleType.recurring
                      }
                    >
                      <Text size="sm" mb={0}>
                        Cron Window Start
                      </Text>
                      <DataDisplayWrapper
                        hasData={
                          planData?.schedule_plan?.scheduleCron !== undefined
                        }
                      >
                        {planData?.schedule_plan?.scheduleCron}
                      </DataDisplayWrapper>
                      <Text size="sm" mb={0}>
                        Schedule Offset
                      </Text>
                      <DataDisplayWrapper
                        hasData={
                          planData?.schedule_plan?.scheduleOffset !== undefined
                        }
                      >
                        {planData?.schedule_plan?.scheduleOffset}{" "}
                        {planData?.schedule_plan?.scheduleOffsetUnit ??
                          TimeUnits.minutes}
                      </DataDisplayWrapper>
                    </Case>
                    <Case
                      condition={
                        planData?.schedule_type === ScheduleType.specifiedDates
                      }
                    >
                      <Text size="sm" mb={0}>
                        Specified dates
                      </Text>
                      <FlexContainer direction="column">
                        {planData?.schedule_plan?.scheduleExecutionDates?.map(
                          (date) => {
                            return (
                              <div
                                key={`${date.executionDate}${date.executionTime}`}
                              >
                                {date.executionDate} {date.executionTime}
                              </div>
                            );
                          }
                        )}
                      </FlexContainer>
                    </Case>
                    <Case
                      condition={
                        planData?.schedule_type === ScheduleType.onDemand
                      }
                    >
                      <Text size="sm" mb={0}>
                        Schedule Type
                      </Text>
                      <Text size="sm" mb={0}>
                        On Demand
                      </Text>
                    </Case>
                  </SwitchComponent>
                </div>
                <div>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={() => updateState({ currentStep: 1, subStep: 0 })}
                  />
                </div>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </When>
      <When condition={step === 1}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Spacer height="1rem" />
            <When condition={() => true}>
              <Row>
                <Col>
                  <Message status="success" image="success">
                    Plan has been added
                  </Message>
                </Col>
              </Row>
              <Spacer height="2rem" />
            </When>
            <Row>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => {
                    history.push(ROUTES.plans.generateDetails(planId));
                  }}
                >
                  <SVGIcon name="detailsView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Plan Details
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => history.push(ROUTES.plans.index)}
                >
                  <SVGIcon name="listView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    List of Plan
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard align="center" onClick={onAddNewPlan}>
                  <SVGIcon name="wizardView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Add Another Plan
                  </Heading>
                </InfoCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </When>
    </>
  );
}
