/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Table, theme } from "@nordcloud/gnui";
import { SelectedPlanEntity } from "../types";
import { ResourceGroupSummary } from "./ResourceGroupsSummary";

export function PlanActionSummary({ action }: { action: SelectedPlanEntity }) {
  return (
    <>
      <Table.td>
        <ActionRow>{action.name}</ActionRow>
      </Table.td>
      <Table.td
        css={{
          padding: `${theme.spacing.spacing03}`,
        }}
      >
        <ResourceGroupSummary action={action} />
      </Table.td>
    </>
  );
}

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${theme.spacing.spacing03};
`;
